/* tslint:disable */
/* eslint-disable */
/**
 * accounts_api
 * Accounts rest api
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: cloudharness@metacell.us
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GetUsers200Response
 */
export interface GetUsers200Response {
    /**
     * 
     * @type {Array<User>}
     * @memberof GetUsers200Response
     */
    'users'?: Array<User>;
}
/**
 * 
 * @export
 * @interface UpdatePassword
 */
export interface UpdatePassword {
    /**
     * 
     * @type {string}
     * @memberof UpdatePassword
     */
    'old_password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassword
     */
    'new_password'?: string;
}
/**
 * Keycloak User
 * @export
 * @interface User
 */
export interface User {
    [key: string]: any;

    /**
     * user id
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * Username of the keycloak user
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * Email address of the keycloak user
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * First Name of the keycloak user
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * Last Name of the keycloak user
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof User
     */
    'profiles'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'registrationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'orcid'?: string;
}
/**
 * 
 * @export
 * @interface Valid
 */
export interface Valid {
    /**
     * 
     * @type {string}
     * @memberof Valid
     */
    'response'?: string;
}

/**
 * InfrastructureApi - axios parameter creator
 * @export
 */
export const InfrastructureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Test if application is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        live: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/live`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test if application is ready to take requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ready: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ready`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfrastructureApi - functional programming interface
 * @export
 */
export const InfrastructureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfrastructureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Test if application is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async live(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.live(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test if application is ready to take requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ready(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ready(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfrastructureApi - factory interface
 * @export
 */
export const InfrastructureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfrastructureApiFp(configuration)
    return {
        /**
         * 
         * @summary Test if application is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        live(options?: any): AxiosPromise<string> {
            return localVarFp.live(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test if application is ready to take requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ready(options?: any): AxiosPromise<string> {
            return localVarFp.ready(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfrastructureApi - object-oriented interface
 * @export
 * @class InfrastructureApi
 * @extends {BaseAPI}
 */
export class InfrastructureApi extends BaseAPI {
    /**
     * 
     * @summary Test if application is healthy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfrastructureApi
     */
    public live(options?: AxiosRequestConfig) {
        return InfrastructureApiFp(this.configuration).live(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test if application is ready to take requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfrastructureApi
     */
    public ready(options?: AxiosRequestConfig) {
        return InfrastructureApiFp(this.configuration).ready(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createUser', 'requestBody', requestBody)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userid user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('getUser', 'userid', userid)
            const localVarPath = `/users/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of users based on query
         * @param {string} [queryString] query filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (queryString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryString !== undefined) {
                localVarQueryParameter['query_string'] = queryString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userid user id
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userid: string, requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('updateUser', 'userid', userid)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateUser', 'requestBody', requestBody)
            const localVarPath = `/users/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username user name or email
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernameOrcidPut: async (username: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUsernameOrcidPut', 'username', username)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersUsernameOrcidPut', 'body', body)
            const localVarPath = `/users/{username}/orcid`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username user name or email
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernamePasswordPut: async (username: string, updatePassword: UpdatePassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUsernamePasswordPut', 'username', username)
            // verify required parameter 'updatePassword' is not null or undefined
            assertParamExists('usersUsernamePasswordPut', 'updatePassword', updatePassword)
            const localVarPath = `/users/{username}/password`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userid user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of users based on query
         * @param {string} [queryString] query filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(queryString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(queryString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userid user id
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userid: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userid, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username user name or email
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsernameOrcidPut(username: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUsernameOrcidPut(username, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username user name or email
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsernamePasswordPut(username: string, updatePassword: UpdatePassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUsernamePasswordPut(username, updatePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userid user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userid: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of users based on query
         * @param {string} [queryString] query filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(queryString?: string, options?: any): AxiosPromise<GetUsers200Response> {
            return localVarFp.getUsers(queryString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userid user id
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userid: string, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(userid, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username user name or email
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernameOrcidPut(username: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersUsernameOrcidPut(username, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username user name or email
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernamePasswordPut(username: string, updatePassword: UpdatePassword, options?: any): AxiosPromise<void> {
            return localVarFp.usersUsernamePasswordPut(username, updatePassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userid user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userid: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(userid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of users based on query
     * @param {string} [queryString] query filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(queryString?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(queryString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userid user id
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userid: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userid, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username user name or email
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUsernameOrcidPut(username: string, body: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUsernameOrcidPut(username, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username user name or email
     * @param {UpdatePassword} updatePassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUsernamePasswordPut(username: string, updatePassword: UpdatePassword, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUsernamePasswordPut(username, updatePassword, options).then((request) => request(this.axios, this.basePath));
    }
}


