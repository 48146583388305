export const vars = {
  primaryFont: "proxima-nova, sans-serif",
  whiteColor: "#fff",
  primaryColor: "#2173F2",
  btnBorderColor: "#D0D5DD",
  footerBg: "#1D2939",
  sepratorColor: "#EAECF0",
  contentBg: "#F9FAFB",
  contentBorderColor: "#F2F4F7",
  primaryTextColor: "#667085",
  bannerHeadingColor: "#344054",
  shadow: "0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05)",
  primarySubheaderColor: "#0052CC",
  primaryHeaderColor: "#101828",
  backgroundColorForm: "#F9FAFB",
};
